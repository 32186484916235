import moment from "moment-timezone";

const categories = ["time", "serial"];

type DataType = {
  headers: { key: string; label: string }[];
  dataMap: { [key: string]: string }[];
};

const handleTimezone = (data) => {
  const timeZone = moment.tz(data, "Africa/Dakar");
  const timeStamp = timeZone.format("x");
  return timeStamp;
};

const setChartDataValue = (key: string) => {
  return (dataEntry) => {
    if (key === "time") {
      return parseFloat(handleTimezone(dataEntry[key]));
    }
    return [parseFloat(handleTimezone(dataEntry.time)), parseFloat(dataEntry[key])];
  };
};

const setGraphOptionsData = (
  data: any[] = [],
  serialNumber: string
) => {

  return {
    chart: {
      zoomType: "x",
    },
    title: {
      text: `Gráfico de Temperaturas - Número de Série: ${serialNumber}`,
    },
    subtitle: {
      text:
        document.ontouchstart === undefined
          ? "Clique e arraste na área do gráfico para aumentar o zoom"
          : "Aperte o gráfico para aumentar o zoom",
    },
    xAxis: {
      type: "datetime",
      // dateTimeLabelFormats: "%H:%M:%S",
      labels: {
        formatter: function (value) {
          // return Highcharts.dateFormat("%H:%M:%S %b/%e", value.value);
          return moment(value.value).format('HH:mm:ss MMM/DD');
          
        },
      },
    },
    yAxis: {
      title: {
        text: "Temperatura C°",
      },
    },
    legend: {
      enabled: true,
    },
    plotOptions: {
      area: {
        marker: {
          radius: 4,
        },
        lineWidth: 1,
        states: {
          hover: {
            lineWidth: 1,
          },
        },
        threshold: null,
      },
    },
    series: data,
  };
};

export const adaptTemperatureChartDataAsync = (
  data: DataType,
  serialNumber: string
) => {
  return new Promise((resolve) => {
    resolve(adaptTemperatureChartData(data, serialNumber));
  });
};

const adaptTemperatureChartData = (data: DataType, serialNumber: string) => {
  if (!data) {
    return null;
  }

  const seriesData = data.headers
    .filter((header) => !categories.includes(header.key))
    .map((header) => {
      return {
        type: "line",
        name: [header.label],
        data: data.dataMap.map(setChartDataValue(header.key)),
      };
    });


  return setGraphOptionsData(seriesData, serialNumber);
};

export default adaptTemperatureChartData;

import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Switch,
} from "@material-ui/core";
import moment from "moment";
import { sortByDate } from "../../utils/date-time";

moment.locale("pt");
moment.updateLocale("pt", {
  months: [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ],
});

const useStyles = makeStyles((theme) => ({
  root: {},
  options: {
    display: "flex",
    flexDirection: "column",
  },
  label: {
    maxWidth: "250px",
  },
  footer: {
    marginTop: "25px",
  },
  confirmButton: {
    marginLeft: "25px",
  },
}));

type Props = {
  open: boolean;
  periodMap: { [key: string]: string };
  onSelectPeriod: (params: { [key: string]: string }) => void;
  onCancelSelectPeriod: () => void;
};

const LoadDataPeriodSelector = ({
  open,
  periodMap = {},
  onCancelSelectPeriod,
  onSelectPeriod,
}: Props) => {
  const classes = useStyles();
  const [selectedPeriodMap, setSelectedPeriodMap] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [monthsToSelect, setMonthsToSelect] = useState<string[]>([]);

  useEffect(() => {
    setSelectedPeriodMap(periodMap);
    setSelectAll(true);
    adaptPeriodMapToList(periodMap);
  }, [periodMap]);

  const adaptPeriodMapToList = (period: { [key: string]: string } | null) => {
    if (!period) {
      return [];
    }
    const mothList = Object.values(period).sort(sortByDate('YYYY/MM'));

    setMonthsToSelect(mothList);
  };

  const handleSelectPeriod = () => {
    onSelectPeriod(selectedPeriodMap);
  };

  const handleCancel = () => {
    onCancelSelectPeriod();
  };

  const handleChangeCheckbox = (
    { target: { value } }: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    const newSelectedPeriodMap = {
      ...selectedPeriodMap,
      [value]: checked ? value : "",
    };

    setSelectedPeriodMap(newSelectedPeriodMap);

    setSelectAll(
      Object.values(periodMap).every((period) => !!newSelectedPeriodMap[period])
    );
  };

  const handleToggleSelectAll = (
    _event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setSelectAll(checked);
    if (checked) {
      setSelectedPeriodMap(periodMap);
    } else {
      setSelectedPeriodMap({});
    }
  };

  return (
    <Dialog
      open={open}
      fullWidth
      className={classes.root}
      onClose={handleCancel}
    >
      <DialogTitle>Selecione o período que deseja analisar</DialogTitle>
      <DialogContent className={classes.options}>
        <label className={classes.label}>
          <Switch checked={selectAll} onChange={handleToggleSelectAll} />
          <span>Todos</span>
        </label>
        {monthsToSelect.map((period) => (
          <label key={period} className={classes.label}>
            <Checkbox
              value={period}
              checked={!!selectedPeriodMap[period] || false}
              onChange={handleChangeCheckbox}
            />
            <span>{moment(period, "YYYY/MM").format("MMMM [de] YYYY")}</span>
          </label>
        ))}
      </DialogContent>
      <DialogActions className={classes.footer}>
        <Button onClick={handleCancel} color="secondary">
          Cancelar
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleSelectPeriod}
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LoadDataPeriodSelector;

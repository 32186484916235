import React, { useState } from "react";
import moment from "moment";
import {
  Container,
  DialogContent,
  makeStyles,
  Dialog,
  DialogActions,
  Button,
} from "@material-ui/core";
import unZipFileToDataEntries, {
  unZipFileToDataPeriods,
} from "src/utils/unZipFileToDataEntries";
import {
  adaptToEventsTableAsync,
  AlarmTableType,
} from "src/utils/adaptToEventsTable";
import { adaptDailyAverageTemperatureDataAsync } from "src/utils/adaptDailyAverageTemperatureData";
import { TemperatureAverageType } from "src/types/FileTypes";
import { TemperaturesChart } from "src/components/TemperaturesChart";
import MainHeader from "../../components/MainHeader/MainHeader";
import LoadingData from "../../components/LoadingData/LoadingData";
import EmptyDataWarning from "src/components/EmptyDataWarning";
import { DailyTemperatureChart } from "src/components/DailyTemperatureChart";
import { AlarmsTable } from "src/components/AlarmsTable";
import { adaptTemperatureChartDataAsync } from "src/utils/adaptTemperatureChartData";
import LoadDataPeriodSelector from "src/components/LoadDataPeriodSelector";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  body: {
    marginTop: "40px",
  },
  chart: {
    marginBottom: "50px",
  },
  button: {
    borderColor: "#fff",
    color: "#fff",
  },
  empty: {
    padding: "20px",
  },
}));

const setPeriodData = (date1: string): string => {
  return moment(date1, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY");
};

const MainPage = () => {
  const classes = useStyles();

  const [alarmsTableData, setAlarmsTableData] = useState<AlarmTableType[]>([]);
  const [temperatureChartData, setTemperatureChartData] = useState<any>();
  const [measurementPeriod, setMeasurementPeriod] = useState({
    open: "",
    close: "",
  });

  const [
    dailyAverageTemperatureData,
    setDailyAverageTemperatureData,
  ] = useState<TemperatureAverageType[]>([]);
  const [openErrorDialog, setOpenErrorDialog] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [serialNumber, setSerialNumber] = useState<string>("");
  const [isLoadingData, setLoadingData] = useState<boolean>(false);
  const [readPeriods, setReadPeriods] = useState<any>({});
  const [readFiles, setReadFiles] = useState<any>();
  const [enableChangePeriod, setEnableChangePeriod] = useState<boolean>(false);
  const [openPeriodsSelector, setOpenPeriodsSelector] = useState<boolean>(
    false
  );

  const onLoadZipFile = ({
    target: { files },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setLoadingData(false);
    if (!files || !files?.length) {
      return;
    }

    const isInvalidType = files?.[0]?.type.includes("zip");

    if (!isInvalidType) {
      setOpenErrorDialog(true);
      setErrorMessage(
        "Arquivo Inválido. Por favor, selecione uma pasta do tipo .zip"
      );
      return;
    }

    setOpenPeriodsSelector(false);
    setOpenErrorDialog(false);
    setLoadingData(true);
    setEnableChangePeriod(true);

    loadZipFile(files);
  };

  const onChangeDataPeriod = () => {
    setOpenPeriodsSelector(true);
  };

  const handleCancelSelectPeriod = () => {
    setOpenPeriodsSelector(false);
    setLoadingData(false);
  };

  const loadZipFile = async (value: any) => {
    const newReadPeriods = await unZipFileToDataPeriods(value[0]);
    setReadPeriods(newReadPeriods);
    setReadFiles(value[0]);
    setOpenPeriodsSelector(true);
  };

  const handleSelectPeriod = (params: { [key: string]: string }) => {
    setLoadingData(true);
    setOpenPeriodsSelector(false);

    if (Object.values(params).filter((period) => !!period).length) {
      convertFilesToData(readFiles, params);
    } else {
      setLoadingData(false);
      setOpenErrorDialog(true);
      setErrorMessage("Nenhum período de análise foi selecionado.");
    }
  };

  const convertFilesToData = async (
    readFiles: any,
    periodRange: { [key: string]: string }
  ) => {
    const { dataEntriesObject, serialNumber } = await unZipFileToDataEntries(
      readFiles,
      periodRange
    );

    setSerialNumber(serialNumber);

    adaptTemperatureChartDataAsync(dataEntriesObject?.dados, serialNumber)
      .then((data) => {
        setTemperatureChartData(data);
      })
      .catch(() => {
        setOpenErrorDialog(true);
        setErrorMessage(
          "Ops, não conseguimos contruir o Gráfico de Temperaturas para o período selecionado. Certifíque-se que sua base de dados está correta"
        );
      });

    adaptDailyAverageTemperatureDataAsync(dataEntriesObject?.dados?.dataMap)
      .then((data) => {
        setDailyAverageTemperatureData(data);
        setLoadingData(false);
        setOpenErrorDialog(false);
      })
      .catch(() => {
        setOpenErrorDialog(true);
        setErrorMessage(
          "Ops, não conseguimos contruir o Gráfico de Temperaturas diário para o período selecionado.  Certifíque-se que sua base de dados está correta"
        );
      });

    adaptToEventsTableAsync(dataEntriesObject?.bit_alarm_log?.dataMap)
      .then((data) => {
        const length = data.length;
        const open = data?.[0]?.openTime || "";
        const close = data?.[length - 1]?.openTime || "";
        setAlarmsTableData(data);
        setMeasurementPeriod({
          open: setPeriodData(open),
          close: setPeriodData(close),
        });
      })
      .catch(() => {
        setLoadingData(false);
        setOpenErrorDialog(true);
        setErrorMessage(
          "Ops, não conseguimos contruir a Tabela de Ocorrência de Alarmes para o período selecionado. Certifíque-se que sua base de dados está correta"
        );
      });
  };

  const handleCloseErrorDialog = () => {
    setOpenErrorDialog(false);
  };

  return (
    <>
      <LoadDataPeriodSelector
        open={openPeriodsSelector}
        periodMap={readPeriods}
        onSelectPeriod={handleSelectPeriod}
        onCancelSelectPeriod={handleCancelSelectPeriod}
      />
      <MainHeader
        enableChangePeriod={enableChangePeriod}
        onLoadZipFile={onLoadZipFile}
        onChangeDataPeriod={onChangeDataPeriod}
      />
      <Container className={classes.body}>
        <EmptyDataWarning
          visible={!alarmsTableData?.length && !isLoadingData}
        />
        <AlarmsTable
          isLoadingData={isLoadingData}
          dataSet={alarmsTableData}
          serial={serialNumber}
          period={measurementPeriod}
        />
        <TemperaturesChart
          serial={serialNumber}
          isLoadingData={isLoadingData}
          graphOptions={temperatureChartData}
        />
        <DailyTemperatureChart
          serial={serialNumber}
          isLoadingData={isLoadingData}
          data={dailyAverageTemperatureData}
        />
      </Container>

      <Dialog fullWidth open={isLoadingData}>
        <DialogContent>
          <LoadingData visible={isLoadingData} />
        </DialogContent>
      </Dialog>
      <Dialog fullWidth open={openErrorDialog} onClose={handleCloseErrorDialog}>
        <DialogContent>{errorMessage}</DialogContent>
        <DialogActions>
          <Button color="primary" autoFocus onClick={handleCloseErrorDialog}>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default React.memo(MainPage);

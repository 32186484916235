import { makeStyles } from "@material-ui/core";
import React from "react";
import logoImg from "../../assets/logo-light.png";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "180px",
    alignItems: "center",
  },
  img: {
    width: "100%",
  },
  subLogo: {
    fontSize: "10px",
  },
}));

const Logo = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img className={classes.img} src={logoImg} alt="" />
      <span className={classes.subLogo}>Equipamentos para imunobiológicos</span>
    </div>
  );
};

export default Logo;

import React from "react";
import { makeStyles, Paper } from "@material-ui/core";
import { Chart } from "../ui";

type Props = {
  serial: string;
  graphOptions: any;
  isLoadingData: boolean;
};

const useStyles = makeStyles({
  graph: {
    padding: 20,
    marginBottom: 50,
    marginTop: 50,
  },
});

const TemperaturesChart = ({ graphOptions, isLoadingData }: Props) => {
  const classes = useStyles();

  if (!graphOptions || isLoadingData) {
    return null;
  }

  return (
    <Paper className={classes.graph}>
      <Chart options={graphOptions} />
    </Paper>
  );
};

export default React.memo(TemperaturesChart);

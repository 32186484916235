import React from "react";
import {
  LinearProgress,
  makeStyles,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  empty: {
    padding: "20px",
  },
}));

type Props = {
  visible: boolean;
};

const LoadingData = ({ visible }: Props): JSX.Element | null => {
  const classes = useStyles();

  if (!visible) {
    return null;
  }

  return (
    <div className={classes.root}>
      <LinearProgress />
      <Typography className={classes.empty}>Carregando Dados</Typography>
    </div>
  );
};

export default LoadingData;

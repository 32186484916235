import React from "react";
import Highcharts from "highcharts/highstock";
import { CssBaseline, ThemeProvider, createMuiTheme } from "@material-ui/core";
import { MainPage } from "./pages";
import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsExportingOffline from "highcharts/modules/offline-exporting";

// init the module
HighchartsExporting(Highcharts);
HighchartsExportingOffline(Highcharts);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#1573a4",
      contrastText: "#fff",
    },
    secondary: {
      main: "#331874",
      contrastText: "#fff",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <MainPage />
    </ThemeProvider>
  );
}

export default App;

import React from "react";
// import * as Highcharts from "highcharts";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";

interface Props {
  options?: {
    chart: any;
    title: {
      text: string;
    };
  };
}
// const timezone: any = new Date().getTimezoneOffset();

// Highcharts.setOptions({
//   global: {
//     timezone: timezone,
//   },
// });

/* The Generic graph that we need to change only the options property */
const Chart = (props: Props) => {
  const { options } = props;

  return (
    <HighchartsReact
      constructorType="stockChart"
      highcharts={Highcharts}
      options={options}
    />
  );
};

export default Chart;

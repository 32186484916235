import React, { Component } from "react";
import { CSVLink } from "react-csv";
import { AlarmTableType } from "src/utils/adaptToEventsTable";

type CsvReportType = {
  filename: string;
  headers: { [key: string]: string }[];
  data: { [key: string]: string }[];
};

type Props = {
  period: { open: string; close: string };
  dataSet: AlarmTableType[];
};

type StateProps = {
  csvReport: CsvReportType;
};

class DownloadCsv extends Component<Props, StateProps> {
  constructor(props) {
    super(props);
    this.state = {
      csvReport: {
        data: [],
        headers: [],
        filename: "Report.csv",
      },
    };
  }

  adaptCsvReport = (dataSetInformed: AlarmTableType[]): CsvReportType => {
    const headers = [
      { label: "Data e Hora", key: "openTime" },
      { label: "Tipo de Alarme", key: "description" },
      { label: "Duração", key: "duration" },
    ];

    const data = dataSetInformed.map((data) => ({
      openTime: data.openTime,
      description: data.description,
      duration: data.duration,
    }));
    return {
      filename: this.state.csvReport.filename,
      headers,
      data,
    };
  };

  downloadReport = (_event, done: () => void) => {
    const { period, dataSet } = this.props;

    const csvData = this.adaptCsvReport(dataSet);
    const prefix = `${period.open.replace(
      /\//g,
      "-"
    )}-to-${period.close.replace(/\//g, "-")}`;
    const objReport = {
      filename: `alarms-from-${prefix}.csv`,
      headers: csvData.headers,
      data: csvData.data,
    };

    this.setState({ csvReport: objReport }, () => {
      done();
    });
  };

  render() {
    const {
      csvReport: { filename, headers, data },
    } = this.state;

    return (
      <>
        <CSVLink
          filename={filename}
          headers={headers}
          data={data}
          onClick={this.downloadReport}
          asyncOnClick={true}
          style={{
            color: '#1573a4',
            textDecoration: "none",
          }}
        >
          Baixar CSV
        </CSVLink>
      </>
    );
  }
}

export default DownloadCsv;

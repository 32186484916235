import JSZip from "jszip";
import * as d3 from "d3";
import { InputByType } from "src/types/FileTypes";
import { sortPeriod } from "./date-time";

export type InputFileFormat = InputByType[keyof InputByType];

export type DataEntriesType = {
  dataEntriesObject: { [key: string]: any };
  serialNumber: string;
};

const csvExtensionRegex = new RegExp(/\.(csv)$/gi);

const toKey = (value: string) => value.replace(/\s/g, "_").trim().toLowerCase();

const isCsvFile = (filename: string): RegExpMatchArray | null =>
  filename.match(csvExtensionRegex);

type Period = {
  [key: string]: string;
};

export const unZipFileToDataPeriods = async (
  value: InputFileFormat
): Promise<Partial<Period>> => {
  const zipFileRead = value;

  if (!zipFileRead) {
    return { };
  }

  const JSZipFiles = new JSZip();

  const zip = await JSZipFiles.loadAsync(zipFileRead);
  // um regx pasta/numero/ano/mes  é melhor
  const months = Object.values(zip.files)
    .filter((file) => file.name.split("/").filter((f) => !!f).length === 4)
    .reduce((total, file) => {
      const keys = file.name.split("/").filter((f) => !!f);
      const key = `${keys[2]}/${keys[3]}`;

      return { ...total, [key]: key };
    }, {});

  return months;
};

const unZipFileToDataEntries = async (
  value: InputFileFormat,
  periodRange: { [key: string]: string }
): Promise<DataEntriesType> => {
  const zipFileRead = value;
  if (!zipFileRead) {
    return {
      dataEntriesObject: {},
      serialNumber: "",
    };
  }

  const JSZipFiles = new JSZip();

  const zip = await JSZipFiles.loadAsync(zipFileRead);

  const dataEntriesArrays = await Promise.all(
    Object.keys(zip.files)
      .filter((filename) => {
        const filenameSplit = filename.split("/");
        const filenameKey = `${filenameSplit[2]}/${filenameSplit[3]}`;
        return !!periodRange[filenameKey] && isCsvFile(filename);
      })
      .map(async (filename) => {
        const fileData = await zip.files[filename].async("string");
        const data = await Promise.all(d3.csvParseRows(fileData));
        return data;
      })
  );

  const dataEntriesMap = dataEntriesArrays
    .map((dataEntriesArray) => {
      const tableName = toKey(dataEntriesArray[0][0]);
      const headers = dataEntriesArray[1].map((header) => ({
        key: toKey(header),
        label: header,
      }));
      const dataTable = dataEntriesArray.slice(2);

      const dataMap = dataTable.map((tableDataInfoArr) => {
        const obj = tableDataInfoArr
          .map((data, index) => ({
            [headers[index].key]: data,
          }))
          .reduce((curr, acc) => ({ ...curr, ...acc }), {});

        return obj;
      });

      return {
        [tableName]: {
          headers,
          dataMap,
        },
      };
    })
    .reduce((totalValue, actualValue, currentIndex) => {
      const key = Object.keys(actualValue)[0];
      const currentDataMap = totalValue[key]?.dataMap || [];

      const dataMap = currentDataMap.concat(actualValue[key].dataMap);

      return {
        ...totalValue,
        [key]: {
          headers: actualValue[key].headers,
          dataMap,
        },
      };
    }, {} as any);

  Object.keys(dataEntriesMap).forEach((key) => {
    dataEntriesMap[key].dataMap.sort((dataA, dataB) =>
      sortPeriod(dataA.time, dataB.time)
    );
  });

  const serialNumber = dataEntriesMap?.dados?.dataMap.find(
    (data) => !!data.serial
  )?.serial;

  return {
    dataEntriesObject: dataEntriesMap,
    serialNumber: serialNumber || "Não informado",
  };
};

export default unZipFileToDataEntries;

import moment from "moment";
import { TemperatureType, TemperatureAverageType } from "src/types/FileTypes";

const handleTimezone = (data: string) => {
  // const timeZone = moment.tz(data, "Africa/Dakar");
  const timeStamp = moment(data).format("x");
  return timeStamp;
};

export const adaptDailyAverageTemperatureData = (
  data: TemperatureType[]
): TemperatureAverageType[] => {
  if (!data || !data.length) {
    return [];
  }

  const partial = data
    .map((item) => ({
      ...item,
      time: moment(item.time, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY"),
      fullTimeStamp: Number(handleTimezone(item.time)),
    }))
    .reduce((current, actual) => {
      const day = actual.time;
      const currentDayValue = current[day] || { pr1: 0, pr2: 0, prCount: 0 };
      const updated = {
        ...currentDayValue,
        time: day,
        fullTimeStamp: actual.fullTimeStamp,
        pr1:
          Number.parseFloat(currentDayValue.pr1) +
          Number.parseFloat(actual.pr1),
        pr2:
          Number.parseFloat(currentDayValue.pr2) +
          Number.parseFloat(actual.pr2),
        prCount: currentDayValue.prCount + 1,
      };

      return {
        ...current,
        [day]: updated,
      };
    }, {} as TemperatureAverageType);

  return Object.values(partial)
    .map((item: TemperatureAverageType) => ({
      ...item,
      pr1: item.pr1 / (item.prCount || 1),
      pr2: item.pr2 / (item.prCount || 1),
    }));
};

export const adaptDailyAverageTemperatureDataAsync = (
  data: TemperatureType[]
): Promise<TemperatureAverageType[]> => {
  return new Promise((resolve) => {
    resolve(adaptDailyAverageTemperatureData(data));
  });
};

import React, { useRef } from "react";
import {
  AppBar,
  Button,
  Container,
  FilledInput,
  makeStyles,
  Toolbar,
  Typography,
} from "@material-ui/core";
import Logo from "./Logo";

type Props = {
  enableChangePeriod: boolean;
  onLoadZipFile: (params: any) => void;
  onChangeDataPeriod: () => void;
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: "linear-gradient(to right, #331874 , #1573a4)",
  },
  title: {
    flexGrow: 1,
  },
  fileInput: {
    position: "absolute",
    display: "none",
    opacity: 0,
    width: 0,
  },
  loadButton: {
    marginRight: "20px",
    background: '#ffffff',
    color: theme.palette.primary.main
  },
  reloadButton: {
    color: "#ffffff",
  },
}));

const MainHeader = ({
  enableChangePeriod,
  onLoadZipFile,
  onChangeDataPeriod,
}: Props) => {
  const classes = useStyles();
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClickOpenFile = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.value = "";
      inputRef?.current?.click();
    }
  };

  const handleLoadZipFile = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onLoadZipFile(event);
  };

  return (
    <AppBar position="static" className={classes.root}>
      <Container fixed>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            <Logo />
          </Typography>
          <Button
            variant="contained"
            color="primary"
            className={classes.loadButton}
            onClick={handleClickOpenFile}
          >
            Escolher Arquivo
          </Button>
          <Button
            disabled={!enableChangePeriod}
            color="primary"
            variant="contained"
            onClick={onChangeDataPeriod}
          >
            Alterar Período
          </Button>
          <FilledInput
            inputRef={inputRef}
            className={classes.fileInput}
            type="file"
            name="zipfile"
            id="zipfile"
            onChange={handleLoadZipFile}
          />
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default MainHeader;

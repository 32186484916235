import moment from "moment";

export type DataEntryType = {
  id: string | number;
  alarm_value: string;
  descrp: string;
  resp: string;
  time: string;
  unchain: string;
  value: string;
};

export type DataEntryGroupType = {
  open: DataEntryType;
  close: DataEntryType;
};

export type AlarmTableType = {
  id: string | number;
  openTime: string;
  period: string;
  closeTime: string;
  description: string;
  duration: any;
};

const serialize = (dataSet: DataEntryType[]): DataEntryType[] =>
  dataSet.map((data, i) => ({ ...data, id: i }));

function dataIterators(
  alarmsInformed: DataEntryType[],
  groupedAlarms?: { open: DataEntryType; close: DataEntryType }[]
): any {
  if (!alarmsInformed.length) {
    return groupedAlarms;
  }

  const openAlarm = alarmsInformed.find((alarm) => alarm.value === "1");

  if (!openAlarm) {
    return groupedAlarms;
  }

  const closeAlarm =
    alarmsInformed.find(
      (alarm) =>
        alarm.value === "0" && openAlarm.descrp.trim() === alarm.descrp.trim()
    ) || openAlarm;

  const newAlarmsInformed = alarmsInformed.filter(
    (alarm) => alarm.id !== openAlarm.id && alarm.id !== closeAlarm.id
  );

  const newGroup = [
    ...(groupedAlarms || []),
    {
      open: openAlarm,
      close: closeAlarm,
    },
  ];

  return dataIterators(newAlarmsInformed, newGroup);
}

const setPeriod = (date1: string, date2: string): string => {
  return `${moment(date1, "YYYY-MM-DD hh:mm:ss").format(
    "DD/MM/YYYY"
  )} à ${moment(date2, "YYYY-MM-DD hh:mm:ss").format("DD/MM/YYYY")}`;
};

export const adaptToEventsTable = (
  dataEntriesObject: DataEntryType[]
): AlarmTableType[] => {
  if (!dataEntriesObject) {
    return [];
  }
  return transformTable(dataIterators(serialize(dataEntriesObject)));
};

export const adaptToEventsTableAsync = (
  dataEntriesObject: DataEntryType[]
): Promise<AlarmTableType[]> => {
  return new Promise((resolve) =>
    resolve(adaptToEventsTable(dataEntriesObject))
  );
};

export const transformTable = (
  dataSet: DataEntryGroupType[]
): AlarmTableType[] => {
  return dataSet.map((data) => ({
    id: data.open.id,
    openTime: data.open.time,
    period: setPeriod(data.open.time, data.close.time),
    closeTime: data.close.time,
    description: data.open.descrp,
    duration: getEventDuration(data),
  }));
};

const createMoment = (time: string) => moment(time, "YYYY-MM-DD HH:mm:ss");

const getEventDuration = (data: DataEntryGroupType) => {
  if (data.open.id === data.close.id) {
    return "Desconhecido";
  }

  const timeDiff = createMoment(data.close.time).diff(
    createMoment(data.open.time)
  );

  return moment.utc(timeDiff).format("HH:mm:ss");
};

import React, { useState, useEffect } from "react";
import Table from "@material-ui/core/Table";
import moment from "moment";
import {
  makeStyles,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { AlarmTableType } from "../../utils/adaptToEventsTable";
import DownloadCsv from "./DownloadCsv";

type CsvReportType = {
  headers: { [key: string]: string }[];
  data: { [key: string]: string }[];
};

const setPeriodDataTime = (date1: string): string => {
  return moment(date1, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm:ss");
};

const useStyles = makeStyles({
  root: {
    marginBottom: "50px",
  },
  table: {
    minWidth: 650,
  },
  empty: {
    padding: "20px",
  },
  toolbar: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  subtoolbar: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  CSVLink: {
    textDecoration: "none",
  },
});

type Props = {
  dataSet: AlarmTableType[];
  period: { open: string; close: string };
  isLoadingData: boolean;
  serial?: string;
};
const AlarmsTable = ({
  dataSet = [],
  serial,
  period,
  isLoadingData,
}: Props) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rowsPerPageOptions, setRowsPerPageOptions] = useState<number[]>([]);

  useEffect(() => {
    if (!dataSet) {
      return;
    }
    setRowsPerPageOptions(calcRowsPerPageOptions(dataSet.length));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSet]);

  const calcRowsPerPageOptions = (dataSetLength: number): number[] => {
    return dataSetLength < 100
      ? [10, 25, 50, 100, dataSet?.length]
      : [10, 25, 50, 100];
  };

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    const value = event.target.value;
    const perPage = value === "Todos" ? 1 : +value;

    setRowsPerPage(perPage);
    setPage(0);
  };

  if (!dataSet.length || isLoadingData) {
    return null;
  }

  return (
    <Paper className={classes.root}>
      <TableContainer>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" id="tableTitle">
            Tabela de ocorrência de alarmes da conservadora - Número de Série: {serial}
          </Typography>
          <div className={classes.subtoolbar}>
            <Typography variant="body2">
              Periodo: {period.open} à {period.close}
            </Typography>
           <DownloadCsv period={period} dataSet={dataSet} />
          </div>
        </Toolbar>

        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Data e Hora</TableCell>
              <TableCell align="center">Tipo de Alarme</TableCell>
              <TableCell align="center">Duração</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataSet
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow key={row.id}>
                  <TableCell align="center">
                    {setPeriodDataTime(row.openTime)}
                  </TableCell>
                  <TableCell align="center">{row.description}</TableCell>
                  <TableCell align="center">{row.duration}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        lang="pt"
        component="div"
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
        count={dataSet?.length}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default React.memo(AlarmsTable);

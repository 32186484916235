import React, { useState, useEffect } from "react";
import * as Highcharts from "highcharts";
import { makeStyles, Paper } from "@material-ui/core";
import { Chart } from "../ui";
import { TemperatureAverageType } from "src/types/FileTypes";

const useStyles = makeStyles({
  graph: {
    padding: 20,
    marginBottom: 50,
    marginTop: 50,
  },
});

type Props = {
  isLoadingData: boolean;
  serial: string;
  data?: TemperatureAverageType[];
};

const setGraphOptionsData = (data: any[] = [], time: number[], serial:string) => ({
  chart: {
    zoomType: "x",
    type: "column",
  },
  title: {
    text: `Gráfico de Temperaturas por Dia - Número de Série: ${serial}`,
  },
  subtitle: {
    text:
      document.ontouchstart === undefined
        ? "Clique e arraste na área do gráfico para aumentar o zoom"
        : "Aperte o gráfico para aumentar o zoom",
  },
  xAxis: {
    type: "datetime",
    // categories: time,
    labels: {
      formatter: function (value) {
        return Highcharts.dateFormat("%b/%e", value.value);
      },
    },
  },
  yAxis: {
    title: {
      text: "Temperatura C°",
    },
  },
  selected: true,
  legend: {
    enabled: true,
  },
  tooltip: {
    shared: true,
  },
  plotOptions: {
    column: {
      grouping: true,
      shadow: false,
      borderWidth: 0,
    },
  },

  series: data,
});

const DailyTemperatureChart = ({ data, isLoadingData, serial }: Props) => {

  const classes = useStyles();

  useEffect(() => {
    if (!data?.length) {
      return;
    }

 
    const dataPr1Map = data?.map((day) => [day.fullTimeStamp, Number(day?.pr1.toFixed(2))]);
    const dataPr2Map = data?.map((day) => [day.fullTimeStamp, Number(day?.pr2.toFixed(2))]);
    const dataTimeMap: any = data?.map((day) => day?.fullTimeStamp);

    const dataSet = [
      { name: "pr1", data: dataPr1Map },
      { name: "pr2", data: dataPr2Map },
    ];

    setGraphOptions(setGraphOptionsData(dataSet, dataTimeMap, serial));

  }, [data, serial]);

  const [graphOptions, setGraphOptions] = useState(setGraphOptionsData([], [], serial));

  if (!data?.length || isLoadingData) {
    return null;
  }

  return (
    <Paper className={classes.graph}>
      <Chart options={graphOptions} />
    </Paper>
  );
};

export default React.memo(DailyTemperatureChart);

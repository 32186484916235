import React from "react";
import { makeStyles, Paper, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "400px",
    display: 'flex',
    alignItems:'center',
    justifyContent:'center',
  },
  empty: {
    padding: "20px",
  },
}));

type Props = {
  visible: boolean;
};

const EmptyDataWarning = ({ visible }: Props): JSX.Element | null => {
  const classes = useStyles();

  if (!visible) {
    return null;
  }

  return (
    <Paper className={classes.root}>
      <Typography className={classes.empty}>
        Nenhum dado encontrado. Clique no botão <strong>Escolher Arquivos</strong>
      </Typography>
    </Paper>
  );
};

export default EmptyDataWarning;

import moment from "moment";

export const sortPeriod = (a: string, b: string): number => {
  return moment(a, "YYYY-MM-DD hh:mm:ss").diff(
    moment(b, "YYYY-MM-DD hh:mm:ss")
  );
};

export const sortByDate = (dateFormat: string = "YYYY-MM-DD hh:mm:ss") => (
  a: string,
  b: string
): number => {
  return moment(a, dateFormat).diff(moment(b, dateFormat));
};
